import styles from './Preview.module.css';

import React from 'react';

const Preview = ({onClick, title}) => {
  const previewCards = [
    {id: 'preview-card-1', icon: '❓', title: 'Ответьте на\u00A0вопросы'},
    {id: 'preview-card-2', icon: '⚖️', title: 'Узнайте, что говорит закон о вашей ситуации'},
    {id: 'preview-card-3', icon: '🎓', title: 'Передайте решение вашей проблемы юристу'}
  ];

  return (
    <div className={styles.component}>
      <div className={`${styles.section} ${styles.sectionTime}`}>
        <div className={styles.time}>5 мин</div>
      </div>
      <div className={`${styles.section} ${styles.sectionTitle}`}>
        <div className={styles.title}>{`Узнайте, как ${title.toLowerCase()}`}</div>
      </div>
      <div className={`${styles.section} ${styles.sectionCards}`}>
        <div className={styles.cards}>
          {previewCards.map(previewCard => (
            <div className={styles.cardsSection} key={previewCard.id}>
              <div className={styles.card}>
                <div className={styles.cardIcon}>{previewCard.icon}</div>
                <div className={styles.cardTitle}>{previewCard.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`${styles.section} ${styles.sectionButton}`}>
        <div onClick={onClick} className={styles.button}>Пройти тест</div>
      </div>
    </div>
  );
};

export default Preview;