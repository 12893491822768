import styles from './Hint.module.css';

import React, { useEffect }  from 'react';

const Hint = ({ children, template = 'default', title }) => {
  const contentRef = React.useRef();

  const handleOver = () => {
    contentRef.current.classList.remove(styles.top);

    const rect = contentRef.current.getBoundingClientRect();
    const destraQuizFinalScreen = document.getElementById('destraQuizFinalScreen');

    if (rect.right + 10 >= destraQuizFinalScreen.offsetWidth) {
      contentRef.current.style.position = 'absolute';
      if (450 > destraQuizFinalScreen.offsetWidth) {
        contentRef.current.style.left = 0;
      } else { 
        contentRef.current.style.left = `-${rect.width}px`;
      }
      if (contentRef.current) contentRef.current.style.opacity = '1';
    } else if (rect.bottom >= destraQuizFinalScreen.offsetHeight) {
      contentRef.current.classList.add(styles.top);
    }
  };

  return (
    <span className={`${styles.component} ${template ? styles[template] : ''}`} onMouseOver={handleOver}>
      <span className={`${styles.section} ${styles.sectionIcon}`}>
        <span className={`${styles.icon} ${template ? styles[`icon--${template}`] : ''}`} />
      </span>
      {title && (
        <span className={`${styles.section} ${styles.sectionTitle}`}>
          <span className={styles.title}>
            {title}
          </span>
        </span>
      )}
      <span className={`${styles.section} ${styles.sectionContent}`} ref={contentRef}>
        <div className={styles.content}>
          {children}
        </div>
      </span>
    </span>
  );
};
export default Hint;
