import styles from '../Icon.module.css';
import React from 'react';

export default ({ color = '#FFFFFF' }) => (
  <svg className={`${styles.component} ${styles.docCheck}`} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle opacity='0.2' cx='15' cy='15' r='15' fill={color} />
    <path d='M16.5 7H8.83333C7.82 7 7 7.82 7 8.83333V19.1667C7 20.18 7.82 21 8.83333 21H14.26C13.6733 20.2 13.3333 19.2267 13.3333 18.1667C13.3333 15.5 15.5 13.3333 18.1667 13.3333C18.22 13.3333 18.28 13.3333 18.3333 13.34V8.83333C18.3333 7.82 17.5133 7 16.5 7ZM9.66667 9.66667H12.3333C12.7 9.66667 13 9.96667 13 10.3333C13 10.7 12.7 11 12.3333 11H9.66667C9.3 11 9 10.7 9 10.3333C9 9.96667 9.3 9.66667 9.66667 9.66667ZM13 16.3333H9.66667C9.3 16.3333 9 16.0333 9 15.6667C9 15.3 9.3 15 9.66667 15H13C13.3667 15 13.6667 15.3 13.6667 15.6667C13.6667 16.0333 13.3667 16.3333 13 16.3333ZM15.6667 13.6667H9.66667C9.3 13.6667 9 13.3667 9 13C9 12.6333 9.3 12.3333 9.66667 12.3333H15.6667C16.0333 12.3333 16.3333 12.6333 16.3333 13C16.3333 13.3667 16.0333 13.6667 15.6667 13.6667Z' fill={color} />
    <path d='M18.1667 21.6667C16.2367 21.6667 14.6667 20.0967 14.6667 18.1667C14.6667 16.2367 16.2367 14.6667 18.1667 14.6667C20.0967 14.6667 21.6667 16.2367 21.6667 18.1667C21.6667 20.0967 20.0967 21.6667 18.1667 21.6667ZM18.1667 16C16.972 16 16 16.972 16 18.1667C16 19.3613 16.972 20.3334 18.1667 20.3334C19.3613 20.3334 20.3333 19.3613 20.3333 18.1667C20.3333 16.972 19.3613 16 18.1667 16Z' fill={color} />
    <path d='M22.3334 23C22.1627 23 21.992 22.9347 21.862 22.8047L19.6954 20.638C19.4347 20.3773 19.4347 19.956 19.6954 19.6953C19.956 19.4347 20.3774 19.4347 20.638 19.6953L22.8047 21.862C23.0654 22.1227 23.0654 22.544 22.8047 22.8047C22.6747 22.9347 22.504 23 22.3334 23Z' fill={color} />
    <defs>
      <rect width='16' height='16' fill={color} transform='translate(7 7)' />
    </defs>
  </svg>
);
