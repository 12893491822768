import React, { Component } from 'react';

import generateFilter from '../../../../helpers/generateFilter';
import Loader from '../../../containers/Loader';
import { getInstruction } from '../../../../bootstrap/services/widgetService';
import isWindowDefined from '../../../../helpers/isWindowDefined';

export default function (WrappedComponent) {
  return class WithInstructionFilter extends Component {

    constructor() {
      super();
      
      this.state = {
        instruction: {},
        filter: {}
      };
    }

    componentDidMount() {
      const { instructionId } = this.props;

      if (instructionId) {
        getInstruction(instructionId).then(res => {
          if (res.success) {
            const instruction = res.result;
            this.setState({instruction, filter: generateFilter(instruction, false)});
          }
        });
      }
    } 

    handleChange = (id, newValue, type, answered) => {
      const { filter } = this.state;
      const selectedId = filter[id];
  
      if (type === 'multiselect') {
        if (selectedId.includes(newValue)) {
          const index = selectedId.indexOf(newValue);
  
          if (newValue !== -1) selectedId.splice(index, 1);
        } else {
          selectedId.push(newValue);
        }
      } else if (type === 'select' || type === 'radio') {
        filter[id] = [newValue];
      } else {
        filter[id] = newValue;
      }
  
      Object.keys(filter).map((item) => {
        if (!answered.includes(item) && type !== 'multiselect') filter[item] = [];
      });
  
      this.setState({ filter });
    }

    render() {
      const { instruction, filter } = this.state;
      const { instructionId } = this.props;
      const theme = isWindowDefined() && window.destraParams && window.destraParams.color && window.destraParams.color === 'white' ? 'white' : 'blue';

      if (!instructionId) return 'Не указан id инструкции в атрибуте. Пример: quiz-id="purchase-returns"';
      if (Object.keys(instruction).length === 0) return <Loader />;

      const props = {
        ...this.props,
        handleChange: this.handleChange, 
        instruction,
        filter,
        theme
      }

      return <WrappedComponent {...props} />;
    }
  };
}
