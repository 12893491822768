import './variables.css';

import InstructionFilter from './components/widgets/InstructionFilter';
import React from 'react';
import isWindowDefined from './helpers/isWindowDefined';

const App = ({injectTo}) => {
  const instructionId = isWindowDefined() 
    && window.destraParams 
    && window.destraParams.instructionId 
  ? window.destraParams.instructionId 
  : injectTo.getAttribute("instruction-id");
  const quizType = injectTo.getAttribute("widget-type");
  const isPreviewShown = injectTo.getAttribute("no-preview");

  return <InstructionFilter instructionId={instructionId} quizType={quizType || 'order-quiz'} noPreview={isPreviewShown || false} />
}
export default App;
