import styles from './Icon.module.css';
import React from 'react';

import Pack from './Pack';

const Icon = (props) => {
  const { name } = props;
  const Component = Pack[name];

  if (Component) return <Component {...props} />;

  return <i className={`${styles.component} ${styles[name]}`} />;
};
export default Icon;
