import styles from '../Icon.module.css';
import React from 'react';

export default () => (
  <svg className={`${styles.component} ${styles.pdf}`} viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M39.167 7.50016H45.8337L39.167 0.833496V7.50016Z' fill='#F1F3F5' />
    <path d='M4.16699 25.8335H45.8337V44.1668H4.16699V25.8335Z' fill='#F1F3F5' />
    <path d='M46.659 7.5C46.6615 7.27987 46.5764 7.06787 46.4222 6.91081L39.7555 0.244141C39.5985 0.0903321 39.3865 0.00528971 39.1663 0.00813802V0H7.49967C5.19946 0.00284831 3.33586 1.86646 3.33301 4.16667V45.8333C3.33586 48.1335 5.19946 49.9972 7.49967 50H42.4997C44.7999 49.9972 46.6635 48.1335 46.6663 45.8333L46.659 7.5ZM39.9997 2.84505L43.8213 6.66667H39.9997V2.84505ZM7.49967 1.66667H38.333V7.5C38.333 7.96021 38.7061 8.33333 39.1663 8.33333H44.9997V25H4.99967V4.16667C4.99967 2.78605 6.11906 1.66667 7.49967 1.66667ZM44.9997 43.3333H4.99967V26.6667H44.9997V43.3333ZM42.4997 48.3333H7.49967C6.11906 48.3333 4.99967 47.2139 4.99967 45.8333V45H44.9997V45.8333C44.9997 47.2139 43.8803 48.3333 42.4997 48.3333Z' fill='#5E9CDF' />
  </svg>
  );
