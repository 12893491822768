import styles from '../Icon.module.css';
import React from 'react';

export default () => (
  <svg className={`${styles.component} ${styles.follow}`} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path className={styles.zone} d='M19.4493 7.9493H17.0507V5.5507C17.0507 5.24657 16.8041 5 16.5 5C16.1958 5 15.9493 5.24657 15.9493 5.5507V7.9493H13.5507C13.2466 7.9493 13 8.19587 13 8.5C13 8.80413 13.2466 9.0507 13.5507 9.0507H15.9493V11.4493C15.9493 11.7535 16.1958 12 16.5 12C16.8041 12 17.0507 11.7535 17.0507 11.4493V9.0507H19.4493C19.7534 9.0507 20 8.80413 20 8.5C20 8.19587 19.7534 7.9493 19.4493 7.9493Z' fill='#5E9CDF' />
    <path className={styles.zone} d='M7.84691 11.4831C3.52013 11.4831 0 15.0032 0 19.33C0 19.7 0.3 20 0.670042 20H15.0239C15.3939 20 15.6939 19.7 15.6939 19.33C15.6939 15.0032 12.1737 11.4831 7.84691 11.4831Z' fill='#5E9CDF' />
    <path className={styles.zone} d='M7.84729 0C5.4992 0 3.58887 1.91038 3.58887 4.25852C3.58887 6.60666 5.4992 8.517 7.84729 8.517C10.1954 8.517 12.1058 6.60666 12.1058 4.25852C12.1058 1.91038 10.1954 0 7.84729 0Z' fill='#5E9CDF' />
  </svg>
);
