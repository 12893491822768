import styles from './Multiselect.module.css';

import React, { useEffect, useState } from 'react';

import Render from '../../containers/Render';
import Hint from '../Hint';
import Button from '../Button';

// TODO: Why there is an array of OPTIONS inside VALUE ?

const Multiselect = ({ value, defaultValue, id, onChange, type, withButton, onButtonClick }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  const handleSelect = (option) => {
    onChange(id, option.id, type);
    if (!defaultValue) {
      setSelected([...selected, option.id]);

      if (selected.includes(option.id)) {
        const array = [...selected];
        const index = array.findIndex(item => option.id === item);

        if (index !== -1) {
          array.splice(index, 1);
          setSelected(array);
        }
      }
    }
  };

  return (
    <div className={styles.component}>
      {value.map((option) => {
        const params = {
          type: 'checkbox',
          name: option.id,
          defaultChecked: selected.includes(option.id) || (option.isActive === false ? !option.isActive : null)
        };

        return (
          <div key={`${id}_${option.id}`} className={styles.section}>
            <div role='checkbox' tabIndex={-1} onClick={() => handleSelect(option)} className={styles.field}>
              <div className={`${styles.fieldSection} ${styles.fieldSectionInput}`}>
                <div className={`${styles.input} ${styles[(selected.includes(option.id) || (option.isActive === false ? !option.isActive : null)) ? 'input--active' : 'input--inactive']}`}>
                  <input {...params} className={styles.hidden} />
                </div>
              </div>
              <div className={`${styles.fieldSection} ${styles.fieldSectionLabel}`}>
                <label className={`${styles.label} ${styles[(selected.includes(option.id) || (option.isActive === false ? !option.isActive : null)) ? 'label--active' : 'label--inactive']}`} htmlFor={option.id}>
                  {option.value ? option.value : 'Без типа'}
                </label>
                {!!option.hint && (
                <div className={styles.hint}>
                  <Hint template='control'>
                    <Render element={{ value: option.hint }} />
                  </Hint>
                </div>
              )}
              </div>
            </div>
          </div>
        );
      })}
      {withButton ? (
        <div className={`${styles.fieldSection} ${styles.fieldSectionNext}`}>
          <Button onClick={() => onButtonClick(id)} size='medium'>Далее</Button>
        </div>
      ) : null}
    </div>
  );
};
export default Multiselect;
