import styles from '../Icon.module.css';
import React from 'react';

export default () => (
  <svg className={`${styles.component} ${styles.lock}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 39'>
    <g fill='none' fillRule='nonzero'>
      <path fill='#4E92DC' d='M14.762 27.321v3.748c0 .391.342.709.738.709.408 0 .738-.33.738-.71v-3.747a2.171 2.171 0 0 0 1.476-2.043c0-1.197-.991-2.167-2.214-2.167-1.223 0-2.214.97-2.214 2.167 0 .943.616 1.746 1.476 2.043zm-1.476.846a3.58 3.58 0 0 1-1.476-2.89c0-1.994 1.652-3.61 3.69-3.61 2.038 0 3.69 1.616 3.69 3.61a3.58 3.58 0 0 1-1.476 2.89v2.886c0 1.2-.991 2.17-2.214 2.17-1.231 0-2.214-.972-2.214-2.17v-2.886zM4.429 15.889v-5.057C4.429 4.85 9.385 0 15.5 0c6.102 0 11.071 4.85 11.071 10.832v5.057c2.45.007 4.429 1.95 4.429 4.347v14.417C31 37.069 29.011 39 26.558 39H4.442C1.986 39 0 37.054 0 34.653V20.236c0-2.412 1.982-4.34 4.429-4.347zm-.004 1.444c-1.629 0-2.949 1.296-2.949 2.898v14.427c0 1.6 1.315 2.898 2.949 2.898h22.15c1.629 0 2.949-1.296 2.949-2.898V20.231c0-1.6-1.315-2.898-2.949-2.898H4.425zm4.432-6.498v5.054h13.286v-5.054c0-3.591-2.982-6.502-6.643-6.502-3.669 0-6.643 2.903-6.643 6.502z' />
      <path fill='#FFF' d='M5.905 10.824c0-5.179 4.296-9.38 9.595-9.38 5.289 0 9.595 4.2 9.595 9.38v5.065H23.62v-5.043c0-4.394-3.644-7.957-8.119-7.957-4.484 0-8.119 3.555-8.119 7.957v5.043H5.905v-5.065z' />
    </g>
  </svg>
);
