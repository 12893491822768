import styles from './Collapse.module.css';

import React, { useRef } from 'react';
import shortid from 'shortid';

const Collapse = ({ title, children, template }) => {
  const collapse = useRef();
  return (
    <div className={`${styles.component} ${template ? styles[template] : styles.default}`} ref={collapse}>
      <div className={styles.title} onClick={() => collapse.current.classList.toggle(styles.open)}>
        {title}
      </div>
      {Array.isArray(children)
          ? children.map(child => <div key={shortid.generate()} className={styles.content}>{child}</div>)
          : <div className={styles.content}>{children}</div>
        }
    </div>
  );
};
export default Collapse;
