import styles from './Button.module.css';

import React from 'react';
import Icon from '../Icon';

const Button = ({  
  color='blue',
  size='default',
  template,
  type = 'functional',
  loading = false,
  children,
  onClick,
  href,
  disabled,
  icon,
  iconColor,
}) => {
  
  const renderIcon = () => {
    if (icon) {
      return (
        <span className={styles.sectionIcon}>
          <Icon color={iconColor} name={icon} />
        </span>
      );
    }
  };

  const renderContent = () => {
    if (children) {
      return (
        <span className={`${styles.section} ${styles.sectionContent}`}>
          {children}
        </span>
      );
    }
  };

  const renderLoader = () => {
    if (loading) {
      return (
        <span className={`${styles.section} ${styles.sectionLoader}`}>
          <span className={styles.loader} />
        </span>
      );
    }
  };

  const params = { className: `${styles.component} ${size ? styles[size] : ''} ${color ? styles[color] : ''} ${template ? styles[template] : ''}`, disabled };

  switch (type) {
    case 'internal':
      return (
        <a href={href} {...params}>
          {renderIcon()}
          {renderContent()}
          {renderLoader()}
        </a>
      );
    case 'external':
      return (
        <a href={href} {...params}>
          {renderIcon()}
          {renderContent()}
          {renderLoader()}
        </a>
      );
    case 'functional':
    default:
      return (
        <button type='button' onClick={onClick} {...params}>
          {renderIcon()}
          {renderContent()}
          {renderLoader()}
        </button>
      );
  }
};

export default Button;