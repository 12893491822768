import styles from '../Icon.module.css';
import React from 'react';

export default () => (
  <svg className={`${styles.component} ${styles.docSearch}`} viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.2'>
      <mask id='path-1-inside-1' fill='white'>
        <rect width='16' height='20' rx='1' />
      </mask>
      <rect width='16' height='20' rx='1' stroke='#1E1E1E' strokeWidth='2.8' mask='url(#path-1-inside-1)' />
    </g>
    <g opacity='0.2'>
      <path d='M3 4H5' stroke='#1E1E1E' strokeWidth='1.4' strokeLinecap='round' />
      <path d='M7 4L13 4' stroke='#1E1E1E' strokeWidth='1.4' strokeLinecap='round' />
      <path d='M3 8H13' stroke='#1E1E1E' strokeWidth='1.4' strokeLinecap='round' />
      <path d='M3 16H11' stroke='#1E1E1E' strokeWidth='1.4' strokeLinecap='round' />
      <path d='M3 12H7' stroke='#1E1E1E' strokeWidth='1.4' strokeLinecap='round' />
      <path d='M9 12L13 12' stroke='#1E1E1E' strokeWidth='1.4' strokeLinecap='round' />
    </g>
  </svg>

);
