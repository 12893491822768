import styles from './Note.module.css';

import React from 'react';

const Note = ({ children }) => (
  <div className={styles.component}>
    {children}
  </div>
);
export default Note;
