import styles from './AnalyticsLoader.module.css';

import React, { useEffect, useState } from 'react';
import returnRandomNumber from '../../../helpers/returnRandomNumber';
import ProgressBar from '../../ui/ProgressBar';

const AnalyticsLoader = ({ onProgressEnd, quantity, progress }) => {
  const [_progress, handleProgress] = useState(progress || 0);

  useEffect(() => {
    if (_progress < quantity) {
      setTimeout(() => handleProgress(_progress + Number(`${returnRandomNumber(2, 5)}0`)), 1000);
    } else if (onProgressEnd) onProgressEnd();
  }, [_progress]);

  return (
    <div className={styles.component}>
      <div className={styles.number}>{`Анализируем ситуацию ${_progress < quantity ? _progress : quantity}%`}</div>
      <div className={styles.progress}>
        <ProgressBar withNumber={false} progress={_progress < quantity ? _progress : quantity} quantity={quantity} />
      </div>
    </div>
  );
};

export default AnalyticsLoader;
