import styles from '../Icon.module.css';
import React from 'react';

export default ({ color = '#B3D6F7' }) => (
  <svg viewBox='0 0 25 24' className={`${styles.component} ${styles.star}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.7701 0.99549C11.5423 -0.331831 13.4577 -0.331829 14.2299 0.995492L16.9098 5.60203C17.1927 6.08825 17.6667 6.43303 18.216 6.55212L23.4202 7.68037C24.9197 8.00546 25.5115 9.82914 24.4893 10.9746L20.9414 14.9498C20.5669 15.3694 20.3859 15.9273 20.4425 16.4871L20.9789 21.7909C21.1334 23.3192 19.5839 24.4463 18.1799 23.8268L13.3073 21.6771C12.7929 21.4502 12.2071 21.4502 11.6927 21.6771L6.82008 23.8268C5.41608 24.4463 3.86656 23.3192 4.02111
    21.7909L4.5575 16.4871C4.61412 15.9273 4.43306 15.3694 4.05859 14.9498L0.510726 10.9746C-0.511549 9.82914 0.0803172 8.00546 1.57984 7.68037L6.78401 6.55212C7.33331 6.43303 7.80731 6.08825 8.09018 5.60203L10.7701 0.99549Z'
      fill={color}
    />
  </svg>

);
