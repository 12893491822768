import styles from './Radio.module.css';

import React, { useEffect, useState } from 'react';

import Render from '../../containers/Render';
import Hint from '../Hint';

const Radio = ({ value, defaultValue, id, onChange, type }) => {
  if (defaultValue === true) defaultValue = [0];
  if (defaultValue === false) defaultValue = [1];
  const _defaultSelected = defaultValue.length > 0 ? value.find(item => item.id === Number(defaultValue)) : undefined;
  const [selected, setSelected] = useState(_defaultSelected);

  const handleSelect = (option) => {
    onChange(id, option.id, type);
    setSelected(option);
  };

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) setSelected(value.find(item => item.id === Number(defaultValue)));
    else if (defaultValue && defaultValue.length === 0) setSelected(undefined);
  }, [defaultValue]);

  return (
    <div className={styles.component}>
      {value.map((option) => {
        const isSelected = selected ? selected.id === option.id : false;

        return (
          <div key={`${id}_${option.id}`} className={`${styles.section} ${styles[isSelected ? 'section--active' : 'section--inactive']}`}>
            <div role='button' tabIndex={-1} className={styles.field} onClick={() => handleSelect(option)}>
              <div className={`${styles.fieldSection} ${styles.fieldSectionButton}`}>
                <div className={`${styles.button} ${styles[isSelected ? 'button--active' : 'button--inactive']}`} />
              </div>
              <div className={`${styles.fieldSection} ${styles.fieldSectionLabel}`}>
                <div className={`${styles.label} ${styles[isSelected ? 'label--active' : 'label--inactive']}`}>{option.value}</div>
                {!!option.hint && (
                  <div className={styles.hint}>
                    <Hint template='control'>
                      <Render element={{ value: option.hint }} />
                    </Hint>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Radio;
