import styles from '../Icon.module.css';
import React from 'react';

export default ({ color = '#FFFFFF' }) => (
  <svg className={`${styles.component} ${styles.vkRounded}`} viewBox='0 0 31 31' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-282.000000, -4438.000000)' fill='#4382bd' fillRule='nonzero'>
        <g transform='translate(0.000000, 3964.000000)'>
          <g transform='translate(101.000000, 298.000000)'>
            <path d='M196.5,207 C187.939586,207 181,200.060414 181,191.5 C181,182.939586 187.939586,176 196.5,176 C205.060414,176 212,182.939586 212,191.5 C212,200.060414 205.060414,207 196.5,207 Z ' />
            <path className={styles.zone} fill={color} fillRule='nonzero' d='M197.009589,196.995336 C197.508904,196.995336 197.508904,196.275209 197.508904,195.995854 C197.508904,195.496113 198.008219,194.996372 198.507535,194.996372 C199.00685,194.996372 199.863175,195.853428 200.504795,196.495595 C201.00411,196.995336 201.00411,196.995336 201.503425,196.995336 C202.00274,196.995336 203.00137,196.995336 203.00137,196.995336 C203.00137,196.995336 204,196.957355 204,195.995854 C204,195.683516 203.657969,195.153791 202.502055,193.996891 C201.503425,192.997409 200.991128,193.513641 202.502055,191.498186 C203.422292,190.270823 204.091874,189.158899 203.977032,188.838065 C203.867182,188.531724 201.307693,188.043977 201.00411,188.499741 C200.00548,189.998964 199.822231,190.366273 199.506165,190.998445 C199.00685,191.997927 198.957417,192.497668 198.507535,192.497668 C198.053657,192.497668 198.008219,191.527671 198.008219,190.998445 C198.008219,189.346302 198.247391,188.177908 197.508904,188 C197.508904,188 196.510274,188 196.010959,188 C195.207062,188 194.513014,188.499741 194.513014,188.499741 C194.513014,188.499741 193.893364,188.98349 194.013699,188.999482 C194.162495,189.019471 195.012329,188.792589 195.012329,189.499223 C195.012329,189.998964 195.012329,190.498704 195.012329,190.498704 C195.012329,190.498704 195.016823,192.497668 194.513014,192.497668 C194.013699,192.497668 193.015069,190.498704 192.016439,188.999482 C191.624477,188.411287 191.517124,188.499741 191.017809,188.499741 C190.482543,188.499741 190.019678,188.52073 189.519864,188.52073 C189.020549,188.52073 188.956636,188.839065 189.020549,188.999482 C190.019179,191.498186 190.736695,193.067372 192.634092,195.044347 C194.374205,196.857907 195.53661,196.954357 196.510274,196.995336 C196.759932,197.00583 196.510274,196.995336 197.009589,196.995336 Z' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
