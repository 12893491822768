import styles from './Text.module.css';

import React from 'react';

const Text = ({ size, template = 'black', children }) => {
  const params = {
    className: `${styles.component} ${size ? styles[`size-${size}`] : ''} ${template ? styles[template] : ''}`
  };

  return <span {...params}>{children}</span>;
};
export default Text;
