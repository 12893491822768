import styles from '../Icon.module.css';
import React from 'react';

export default ({ color = '#5E9CDF' }) => (
  <svg className={`${styles.component} ${styles.blank}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 99 126'>
    <g fill={color} fillRule='nonzero'>
      <path d='M9.263 0h80.474C94.853 0 99 4.14 99 9.248v107.504c0 5.108-4.147 9.248-9.263 9.248H9.263C4.147 126 0 121.86 0 116.752V9.248C0 4.14 4.147 0 9.263 0zm0 1.156c-4.476 0-8.105 3.623-8.105 8.092v107.504c0 4.47 3.629 8.092 8.105 8.092h80.474c4.476 0 8.105-3.623 8.105-8.092V9.248c0-4.47-3.629-8.092-8.105-8.092H9.263z' />
      <path d='M11.762 6h75.476A5.76 5.76 0 0 1 93 11.758v102.484A5.76 5.76 0 0 1 87.238 120H11.762A5.76 5.76 0 0 1 6 114.242V11.758A5.76 5.76 0 0 1 11.762 6zm0 1.152a4.608 4.608 0 0 0-4.61 4.606v102.484a4.608 4.608 0 0 0 4.61 4.606h75.476a4.608 4.608 0 0 0 4.61-4.606V11.758a4.608 4.608 0 0 0-4.61-4.606H11.762z' />
      <path d='M49.5 64C61.374 64 71 54.374 71 42.5S61.374 21 49.5 21 28 30.626 28 42.5 37.626 64 49.5 64zm0-1.162c-11.232 0-20.338-9.106-20.338-20.338 0-11.232 9.106-20.338 20.338-20.338 11.232 0 20.338 9.106 20.338 20.338 0 11.232-9.106 20.338-20.338 20.338z' />
      <path d='M50 42a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0-1.2a4.8 4.8 0 1 1 0-9.6 4.8 4.8 0 0 1 0 9.6zM49.5 57c3.183 0 5.033-.194 6.54-.846C57.99 55.31 59 53.72 59 51.242 59 46.138 54.747 42 49.5 42S40 46.138 40 51.242c0 2.478 1.01 4.068 2.96 4.912 1.507.652 3.357.846 6.54.846zm0-1.15c-6.165 0-8.317-.932-8.317-4.608 0-4.469 3.724-8.091 8.317-8.091s8.317 3.622 8.317 8.09c0 3.677-2.152 4.608-8.317 4.608zM40.316 74h39.368C80.964 74 82 75.12 82 76.5S80.963 79 79.684 79H40.316C39.036 79 38 77.88 38 76.5s1.037-2.5 2.316-2.5zm0 1.25c-.64 0-1.158.56-1.158 1.25s.518 1.25 1.158 1.25h39.368c.64 0 1.158-.56 1.158-1.25s-.518-1.25-1.158-1.25H40.316zM23.14 72.66l3.238-5.186a2.316 2.316 0 0 1 3.926 2.456l-4.897 7.846a2.311 2.311 0 0 1-3.188.733 2.309 2.309 0 0 1-.85-.939 2.325 2.325 0 0 1-.412-.374l-3.039-3.495a2.314 2.314 0 0 1 .226-3.265 2.314 2.314 0 0 1 3.264.231l1.733 1.994zm-2.605-1.234a1.157 1.157 0 1 0-1.744 1.516l3.038 3.496c.063.072.132.134.207.188l.24.17.131.264a1.156 1.156 0 0 0 2.018.102l4.898-7.846a1.158 1.158 0 0 0-1.963-1.228L23.295 74.6l-2.76-3.174zM17.31 86h62.38c1.276 0 2.31 1.12 2.31 2.5S80.966 91 79.69 91H17.31C16.034 91 15 89.88 15 88.5s1.034-2.5 2.31-2.5zm0 1.25c-.638 0-1.155.56-1.155 1.25s.517 1.25 1.155 1.25h62.38c.638 0 1.155-.56 1.155-1.25s-.517-1.25-1.155-1.25H17.31z' />
    </g>
  </svg>
);
