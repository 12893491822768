import styles from './Loader.module.css';

import React from 'react';
import Puff from '../../ui/Puff';

const Loader = () => (
  <div className={styles.component}>
    <div className={styles.animation}>
      <Puff />
    </div>
  </div>
  );
export default Loader;
