import styles from './Control.module.css';

import React from 'react';

import checkCondition from '../../../helpers/checkCondition';
import Render from '../Render';
import Checkbox from '../../ui/Checkbox';
import Select from '../../ui/Select';
import Multiselect from '../../ui/Multiselect';
import Toggle from '../../ui/Toggle';
import Title from '../../ui/Title';
import Hint from '../../ui/Hint';
import Radio from '../../ui/Radio';

const Control = ({ value, filter, handleChange, template = 'black' }) => {
  const { id } = value;

  const filterValuesWithCondition = (values) => {
    const result = [];

    for (let i = 0; i < values.length; i++) {
      const filterValue = values[i];
      const display = checkCondition(filterValue.condition, filter);

      if (display) result.push(filterValue);
    }

    return result;
  };

  const renderControl = () => {
    const { label, _type } = value;
    const params = {
      id: value.id,
      onChange: handleChange,
      label,
      defaultValue: filter ? filter[value.id] : null,
      type: _type,
      value: value.value instanceof Array ? filterValuesWithCondition(value.value) : value.value,
      withButton: value.withButton,
      onButtonClick: value.onButtonClick
    };

    switch (_type) {
      case 'checkbox':
        return <Checkbox {...params} />;
      case 'select':
        return <Select {...params} />;
      case 'multiselect':
        return <Multiselect {...params} />;
      case 'toggle':
        return <Toggle {...params} />;
      case 'radio':
      case 'toggleasradio':
        return <Radio {...params} />;
      default:
    }
  };

  return (
    <div data-id={id} className={`${styles.component} ${styles[value._type]}`}>
      <div className={`${styles.section} ${styles.sectionHeader} ${styles[`section--${value._type}`]}`}>
      <div className={styles.header}>
        <div className={`${styles.headerSection} ${styles.headerSectionTitle}`}>
          <Title level={6} template={template}>{value.label}</Title>
        </div>
        {!!value.hint && (
          <div className={`${styles.headerSection} ${styles.headerSectionHint}`}>
            <Hint template='control'>
              <Render element={{ value: value.hint }} />
            </Hint>
          </div>
        )}
      </div>
      </div>
      <div className={`${styles.section} ${styles.sectionElement} ${styles[`section--${value._type}`]}`}>
        {renderControl()}
      </div>
    </div>
  );
};
export default Control;
