import styles from './Toggle.module.css';

import React from 'react';

const Toggle = ({ onChange, defaultValue, id }) => {
  const settings = {
    role: 'switch',
    onClick: () => onChange(id, !defaultValue),
    className: `${styles.component} ${styles[defaultValue ? 'active' : 'inactive']}`
  };

  return (
    <div {...settings}>
      <input
        type='hidden'
        className={styles.hidden}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default Toggle;
