import styles from '../Icon.module.css';
import React from 'react';

export default ({ color = '#5E9CDF' }) => (
  <svg className={`${styles.component} ${styles.card}`} viewBox='0 0 30 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M29.2655 0.734424C28.7759 0.244892 28.1874 0.000152588 27.5001 0.000152588H2.49998C1.8125 0.000152588 1.22413 0.244837 0.734271 0.734424C0.244684 1.22407 0 1.81244 0 2.49997V21.4997C0 22.1874 0.244684 22.7758 0.734271 23.2654C1.22413 23.755 1.8125 23.9998 2.49998 23.9998H27.5001C28.1874 23.9998 28.7759 23.755 29.2655 23.2654C29.755 22.7759 30 22.1874 30 21.4997V2.49997C30 1.81244 29.755 1.22407 29.2655 0.734424ZM27.9998 21.5001C27.9998 21.6357 27.9504 21.7525 27.8514 21.8518C27.7525 21.9503 27.6353 21.9997 27.5001 21.9997H2.49998C2.36464 21.9997 2.24742 21.9503 2.14847 21.8514C2.04958 21.7522 2.00005 21.6353 2.00005 21.4997V12H27.9998V21.5001H27.9998ZM27.9998 5.99976H2V2.49975C2 2.36436 2.04936 2.24741 2.14842 2.14824C2.24742 2.04957 2.36464 2.00004 2.49993 2.00004H27.5C27.6352 2.00004 27.7525 2.04935 27.8514 2.14824C27.9503 2.24741 27.9997 2.36436 27.9997 2.49975V5.99976H27.9998Z' fill={color} />
    <path d='M7.00007 17.9998H5.00012C4.44784 17.9998 4.00012 18.4476 4.00012 18.9999C4.00012 19.5522 4.44784 19.9999 5.00012 19.9999H7.00006C7.55235 19.9999 8.00006 19.5522 8.00006 18.9999C8.00006 18.4476 7.55235 17.9998 7.00007 17.9998Z' fill={color} />
    <path d='M15.0001 17.9998H11.0001C10.4478 17.9998 10.0001 18.4476 10.0001 18.9999C10.0001 19.5522 10.4478 19.9999 11.0001 19.9999H15.0001C15.5523 19.9999 16.0001 19.5522 16.0001 18.9999C16.0001 18.4476 15.5523 17.9998 15.0001 17.9998Z' fill={color} />
  </svg>
);
