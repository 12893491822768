import styles from '../Icon.module.css';
import React from 'react';

export default () => (
  <svg className={`${styles.component} ${styles.messages}`} viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle opacity='0.1' cx='23.5' cy='23.5' r='23.5' fill='#5E9CDF' />
    <path d='M31.1222 14.3167H19.0445C18.009 14.3167 17.1667 15.159 17.1667 16.1945V18.75H15.8778C14.8423 18.75 14 19.5923 14 20.6275V27.3222C14 28.3577 14.8423 29.2 15.8778 29.2H17.8V32.3667C17.8 32.4975 17.8804 32.6149 18.0027 32.6621C18.0397 32.6764 18.0784 32.6833 18.1167 32.6833C18.2041 32.6833 18.2899 32.6469 18.351 32.5798L21.4233 29.2H27.9555C28.991 29.2 29.8333 28.3577 29.8333 27.3222V24.7667H31.1222C32.1577 24.7667 33 23.9243 33 22.8888V16.1945C33 15.159 32.1577 14.3167 31.1222 14.3167ZM29.2 27.3222C29.2 28.0084 28.6417 28.5667 27.9555 28.5667H21.2833C21.194 28.5667 21.1089 28.6044 21.049 28.6702L18.4333 31.5478V28.8833C18.4333 28.7085 18.2918 28.5667 18.1167 28.5667H15.8778C15.1916 28.5667 14.6333 28.0084 14.6333 27.3222V20.6275C14.6333 19.9416 15.1916 19.3833 15.8778 19.3833H17.4833H27.9555C28.2129 19.3833 28.452 19.4619 28.6509 19.5961C28.9818 19.82 29.2 20.1988 29.2 20.6278V24.45V27.3222ZM32.3667 22.8888C32.3667 23.5751 31.8084 24.1333 31.1222 24.1333H29.8333V20.6278C29.8333 19.7868 29.2773 19.073 28.5135 18.8349C28.4546 18.8165 28.3947 18.801 28.3336 18.7886C28.2114 18.7633 28.085 18.75 27.9555 18.75H17.8V16.1945C17.8 15.5083 18.3583 14.95 19.0445 14.95H31.1222C31.8084 14.95 32.3667 15.5083 32.3667 16.1945V22.8888Z' fill='#5E9CDF' />
    <path d='M17.8001 22.5547H22.2334C22.4085 22.5547 22.5501 22.4129 22.5501 22.2381C22.5501 22.0633 22.4085 21.9214 22.2334 21.9214H17.8001C17.6249 21.9214 17.4834 22.0633 17.4834 22.2381C17.4834 22.4129 17.6249 22.5547 17.8001 22.5547Z' fill='#5E9CDF' />
    <path d='M26.3501 23.8214H17.8001C17.6249 23.8214 17.4834 23.9633 17.4834 24.1381C17.4834 24.3129 17.6249 24.4547 17.8001 24.4547H26.3501C26.5252 24.4547 26.6667 24.3129 26.6667 24.1381C26.6667 23.9633 26.5252 23.8214 26.3501 23.8214Z' fill='#5E9CDF' />
    <path d='M26.3501 25.7214H17.8001C17.6249 25.7214 17.4834 25.8633 17.4834 26.0381C17.4834 26.2129 17.6249 26.3548 17.8001 26.3548H26.3501C26.5252 26.3548 26.6667 26.2129 26.6667 26.0381C26.6667 25.8633 26.5252 25.7214 26.3501 25.7214Z' fill='#5E9CDF' />
  </svg>
  );
