import styles from './OrderedList.module.css';

import React from 'react';
import shortid from 'shortid';

// TODO: Remove the fucking shortid. Component should be controlled.

const OrderedList = ({ children, template = 'default' }) => {
  const content = children instanceof Array ? children : [children];

  return (
    <ol key={shortid.generate()} className={`${styles.component} ${template ? styles[template] : ''}`}>
      {content.map((item) => {
        if (item) {
          const isArray = item.props.children instanceof Array;

          return (
            <li key={shortid.generate()} className={`${styles.item} ${styles[isArray ? 'item--array' : 'item--default']}`}>
              <div className={styles.content}>{item}</div>
            </li>
          );
        }
      })}
    </ol>
  );
};
export default OrderedList;
