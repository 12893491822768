import styles from './List.module.css';

import React from 'react';
import shortid from 'shortid';

// TODO: Remove The fucking shortid. Component must be controlled.

const List = ({ children }) => {
  const content = children instanceof Array ? children : [children];

  return (
    <ul key={shortid.generate()} className={styles.component}>
      {content.map((item) => {
        if (item) {
          const isArray = item.props.children instanceof Array;

          return (
            <li key={shortid.generate()} className={`${styles.item} ${isArray ? styles.array : styles.default}`}>
              <div className={styles.content}>{item}</div>
            </li>
          );
        }
      })}
    </ul>
  );
};
export default List;
