import styles from './Render.module.css';

import React from 'react';
import shortid from 'shortid';
import Title from '../../ui/Title';
import List from '../../ui/List';
import OrderedList from '../../ui/OrderedList';
import Link from '../../ui/Link';
import Text from '../../ui/Text';
import Hint from '../../ui/Hint';
import Note from '../../ui/Note';
import Collapse from '../../ui/Collapse';

const Render = ({ element, template, handleInputChange, values, fieldRef, fieldErrors, handleCallbackInput, price, signatureImage }) => {
  let content;
  if (typeof (element) === 'object') {
    if (element.value instanceof Array) {
    content = element.value.map(children => Render(
      { element: children, fieldErrors, fieldRef, handleInputChange, values, handleCallbackInput, template, price, signatureImage }
));
    } else if (typeof (element.value) === 'object') {
      content = Render({ element: element.value,
fieldErrors,
fieldRef,
handleInputChange,
values,
handleCallbackInput,
      template,
price,
signatureImage });
    } else {
      content = element.value;
    }
  } else {
    content = element;
  }

  let { _type } = element || 'block';
  const key = (element && element._id) || shortid.generate();

  if (element instanceof Object) {
    element.template = template || 'default';
  }

  if (_type && _type.includes(':')) [_type] = _type.split(':');

  switch (_type) {
    case 'title':
      return <Title key={key} {...element}>{content}</Title>;
    case 'ul':
      return <List key={key}>{content}</List>;
    case 'ol':
      return <OrderedList key={key} {...element}>{content}</OrderedList>;
    case 'link':
      return <Link key={key} href={element.href} template='blue' type='external' size={14}>{content}</Link>;
    case 'text':
      return <Text key={key} size={14} {...element}>{content}</Text>;
    case 'hint':
      return <Hint key={key} {...element}>{content}</Hint>;
    case 'collapse':
      return <Collapse key={key} {...element}>{content}</Collapse>;
    case 'note':
      return <Note key={key} {...element}>{content}</Note>;
    case 'b':
      return <b className={styles.bold} key={key}>{content}</b>;
    case 'i':
      return <i className={styles.italic} key={key}>{content}</i>;
    case 'image':
      return <img alt={content} title={content} className='stage__image' src={content} key={key} />;
    case 'iframe':
      return <iframe title={content} className='stage__iframe' src={content} height={element.height} key={key} />;
    case '_render':
      return <div className={styles.component} key={key}>{content}</div>;
    case 'block':
    default:
      return <React.Fragment key={key}>{content}</React.Fragment>;
  }
};

export default props => Render(props);