import styles from './FinalScreen.module.css';

import React, { useState } from 'react';
import Icon from '../../ui/Icon';
import Render from '../Render';
import checkCondition from '../../../helpers/checkCondition';
import compileFormat from '../../../helpers/compileFormat';

const FinalScreen = ({theses, intro, filter, onClick}) => {
  // const [isIntroShown, handleShownIntro] = useState(false);
  // const cards = [
  //   {id: 'final-screen-card-1', icon: 'scales', title: '40 000 ситуаций рассмотрено', color: 'blue'},
  //   {id: 'final-screen-card-2', icon: 'tick', title: '71% положительных решений', color: 'green'}
  // ];
  return (
    <div id='destraQuizFinalScreen' className={styles.component}>
      <div className={`${styles.section} ${styles.sectionIcon}`}>
        <div className={styles.icon}>{theses?.result === false ? '😔' : '🎉'}</div>
      </div>
      {theses ? (                             
        <div className={`${styles.section} ${styles.sectionTheses}`}>
          <div className={styles.theses}>{theses?.label}</div>
        </div>
      ) : null}
      {/* {!isIntroShown ? (
        <div className={`${styles.section} ${styles.sectionExpand}`}>
          <div onClick={()=>handleShownIntro(true)} className={styles.expand}>подробнее</div>
        </div>
      ) : null} */}
      {intro && intro.length ? (
        <div className={`${styles.section} ${styles.sectionIntro}`}>
          {intro.map(item => {
            const compiled = compileFormat(item);
            if (checkCondition(item.condition, filter)) {
              return(
                <div className={styles.intro}  key={item._id}>
                  <Render element={compiled} />
                </div>
              )
            }
          })}
        </div>
      ) : null}
      {/* <div className={`${styles.section} ${styles.sectionCards}`}>
        <div className={styles.cards}>
          {cards.map(card => (
            <div className={styles.cardsSection} key={card.id}>
              <div className={`${styles.card} ${styles[card.color]}`}>
                <div className={styles.cardSection}><Icon name={card.icon} /></div>
                <div className={styles.cardTitle}>{card.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className={`${styles.section} ${styles.sectionButton}`}>
        <div onClick={onClick} className={styles.button}>Узнать подробнее</div>
      </div>
    </div>
  );
};

export default FinalScreen;
