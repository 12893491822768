import styles from '../Icon.module.css';
import React from 'react';

export default () => (
    <svg className={`${styles.component} ${styles.law}`} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.2' cx='16' cy='16' r='16' fill='white' />
      <g>
        <path d='M8.46875 24H16.9688C17.2277 24 17.4375 23.7902 17.4375 23.5313V23.0313C17.4375 22.5134 17.0178 22.0938 16.5 22.0938V21.5333C16.5 20.7913 15.8962 20.1875 15.1542 20.1875H10.2833C9.54126 20.1875 8.9375 20.7913 8.9375 21.5333V22.0938C8.41968 22.0938 8 22.5134 8 23.0313V23.5313C8 23.7901 8.20996 24 8.46875 24Z' fill='white' />
        <path d='M13.8667 18.3232C14.1782 18.7367 14.7661 18.8193 15.1796 18.5077C15.5932 18.1962 15.6757 17.6083 15.3641 17.1947L12.5431 13.4512C12.2315 13.0376 11.6437 12.9551 11.2302 13.2666C10.8167 13.5782 10.734 14.166 11.0457 14.5796L13.8667 18.3232Z' fill='white' />
        <path d='M20.6051 13.2454C20.9168 13.6588 21.5045 13.7415 21.9181 13.4298C22.3316 13.1183 22.4142 12.5305 22.1026 12.117L19.2815 8.37342C18.97 7.95985 18.3821 7.87721 17.9686 8.18885C17.5552 8.5005 17.4726 9.08827 17.7842 9.50184L20.6051 13.2454Z' fill='white' />
        <path d='M18.5488 15.9688C19.2417 15.4467 19.7618 14.7983 20.0872 14.1157L16.8047 9.75977C16.0588 9.8844 15.2924 10.2056 14.5994 10.7278C13.9065 11.2499 13.3864 11.8982 13.061 12.5807L16.3435 16.9369C17.0894 16.8123 17.8558 16.491 18.5488 15.9688Z' fill='white' />
        <path d='M19.8146 16.0908C19.5963 16.3132 19.3665 16.5265 19.113 16.7175C18.8595 16.9084 18.5911 17.0707 18.3173 17.2192L18.9285 18.0304L20.4259 16.9021L19.8146 16.0908Z' fill='white' />
        <path d='M20.9901 17.6508L19.4927 18.7791L22.3137 22.5227C22.6254 22.9363 23.2131 23.0188 23.6266 22.7073C24.0402 22.3956 24.1227 21.8079 23.8112 21.3943L20.9901 17.6508Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='16' height='16' fill='white' transform='translate(8 8)' />
        </clipPath>
      </defs>
    </svg>
);
